aside {
	flex-basis: 15vw;
	min-height: 100vh;
	background-color: var(--primary);
	padding: var(--spacing-half) 20px;

	@include media-breakpoint-down(md) {
		flex-basis: 60px;

		img {
			display: none;
		}
	}

	hr {
		border-color: var(--light);
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 -20px;

		li {
			position: relative;

			a.active + .sub-menu {
				display: block;
				position: relative;
				left: 0;
				background-color: #123053;

				li a:hover {
					background-color: #05182e;
				}
			}

			&:hover {
				background-color: #123053;

				.sub-menu {
					display: block;
				}
			}

			.sub-menu {
				display: none;
				position: absolute;
				top: 0;
				left: 100%;
				background-color: var(--primary);
				padding: 0;
				margin: 0;
				z-index: 1;
				min-width: 150px;

				&.active {
					display: block;
				}

				li {
					position: relative;

					.sub-menu {
						display: none;
						position: absolute;
						top: 0;
						left: 100%;
						background-color: var(--primary);
						padding: 0;
						margin: 0;
						z-index: 1;

						&.active {
							display: block;
						}
					}
				}
			}

			.menu-icon {
				width: 25px;
				display: inline-block;
				flex: 0 0 25px;
			}

			.menu-text {
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			a {
				color: var(--light);
				text-decoration: none;
				padding: 8px 20px;
				display: flex;

				&:hover,
				&.active {
					background-color: #123053;
				}
			}
		}
	}
}

aside + .main-content {
	flex-basis: 85vw;
	width: 85vw;
}
