label {
	font-weight: 500;
}

.form-group-flex {
	margin-bottom: 20px;
}

main {
	display: flex;
}

hr {
	border-color: #9a9a9a;
}

td.field-error {
	background-color: var(--danger);
}

div.field-error {
	background-color: var(--danger);
	color: white;
	padding: 5px;
	border-radius: 3px;
}

.main-content {
	flex-basis: 100vw;
	padding: var(--spacing-half);
	position: relative;
	min-height: 78vh;

	@include media-breakpoint-down(md) {
		padding: var(--spacing-half) 10px;
	}
}

.admin-panel {
	background-color: var(--light);
}

label {
	display: block;
}

.form-check {
	padding-left: 0;
	label {
		display: inline;
		margin-left: 6px;
	}

}

.form-group {
	margin-bottom: 40px;
}

.filters-container {
	background-color: var(--light);
	padding: 20px;
}

.modal {
	background-color: rgba(0, 0, 0, .4);

	.modal-dialog {
		max-width: 75vw !important;
	}
}

.alert {
	padding: 15px;
}

// TinyMCE hide notification about invalid domain
.tox-notifications-container {
	display: none;
}

.floating-buttons {
	position: fixed;
	bottom: 0;
	width: 85vw;
	right: 0;
	background-color: #f1f1f1;
	padding: 20px;
	text-align: center;
	z-index: 1000;
	box-shadow: 0 -2px 10px rgba(0, 0, 0, .2);
	margin-bottom: 0;
}

// .commentary-row-add {
// 	display: none;
// }

// tr:hover + .commentary-row-add {
// 	display: table-row;
// }
.bg-darkgray {
	background-color: var(--dark-gray);
}

.striped {
	form {
		padding: 10px;
		border: 1px solid var(--dark);

		&:nth-child(odd) {
			background-color: var(--light);
		}
	}
}

.duplicate-child {
	td {
		background-color: #e5afaf;
		// color: white;
	}
}

.op-1 {
	opacity: 0.2;
}

.hidden {
	display: none;
}
