.btn {
	&-primary,
	&-secondary,
	&-success,
	&-danger,
	&-warning,
	&-info {
		color: white;

		&:hover {
			color: white;
		}
	}

	&-link {
		padding: 0;
	}

	&-submit {
		svg {
			margin-right: 5px;
		}
	}

	&-new {
		color: var(--success);
		font-size: 26px;
		padding: 5px;
	}

	&-reorder {
		border: 0;
		padding: 0;

		&[disabled] {
			opacity: 0.1;
		}
	}

	&-delete {
		color: var(--danger);
		padding: 0;
	}

	&[disabled] {
		cursor: not-allowed;
		pointer-events: all;
	}
}
