footer {
	background-color: var(--light);
	color: var(--dark);

	.main-footer {
		padding: var(--spacing-half) 0;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;

		li {
			margin: 0 20px;
		}
	}

	.copyright {
		background-color: var(--darker);
		padding: 8px 0;
		color: var(--light);
		text-align: center;
	}
}
