// Bootstrap variable overrides
$enable-negative-margins: true;

$spacer: 30px;
$grid-gutter-width: $spacer;

$primary: #194373;
$secondary: #242E47;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #ecf2f8;
$lighter: #fdfdfd;
$gray: #d4d4d4;
$dark-gray: #737373;
$dark: #343a40;
$darker: #222A35;

// create sass map
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"gray": $gray,
	"dark": $dark,
	"darker": $darker,
	"darkgray": $dark-gray
);

:root {
	// loop sass map to output css vars
	@each $color, $value in $theme-colors {
		--#{$color}: #{$value};
	}

	--spacing: 80px;
	--spacing-half: calc(var(--spacing) / 2);
}

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";


@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";

@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// Helpers
@import "~bootstrap/scss/helpers";
// Utilities
@import "~bootstrap/scss/utilities/api";


// Scrub unused utilities
$utilities: map-merge($utilities,
	("align" : null,
		"float" : null,
		"opacity" : null,
		"overflow" : null,
		"shadow" : null,
		"position" : null,
		"top" : null,
		"bottom" : null,
		"border": null,
		"border-top": null,
		"border-end": null,
		"border-bottom": null,
		"border-start": null,
		"border-color": null,
		"border-width": null,
		"border-opacity": null,
		"max-width": null,
		"viewport-width": null,
		"min-viewport-width": null,
		"max-height": null,
		"viewport-height": null,
		"min-viewport-height": null,
		"negative-margin": null,
		"negative-margin-x": null,
		"negative-margin-y": null,
		"negative-margin-top": null,
		"negative-margin-end": null,
		"negative-margin-bottom": null,
		"negative-margin-start": null,
		"font-family": null,
		"font-size": null,
		"font-style": null,
		"font-weight": null,
		"line-height": null,
		"text-decoration": null,
		"text-transform": null,
		"white-space": null,
		"word-wrap": null,
		"color": null,
		"text-opacity": null,
		"background-color": null,
		"bg-opacity": null,
		"gradient": null,
		"user-select": null,
		"pointer-events": null,
		"rounded": null,
		"rounded-top": null,
		"rounded-end": null,
		"rounded-bottom": null,
		"rounded-start": null,
		"visibility": null
	)
);

@import "./global/base";
@import "./global/buttons";

@import "./components/header";
@import "./components/sidebar";
@import "./components/pagination";
@import "./components/loading";
@import "./components/footer";
@import "./components/timetable";
