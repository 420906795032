.pagination {
	button {
		background-color: transparent;
		border: 2px solid #eaeaea;
		border-radius: 5px;
		padding: 5px 15px;
		margin: 0 5px;
		color: var(--primary);
	}

	ul {
		display: flex;
		list-style: none;
		flex-wrap: wrap;
		justify-content: center;

		li {
			&.active {
				button {
					border-color: var(--primary);
					background-color: var(--primary);
					color: #fff;
				}
			}
			
		}
	}

	.page-goto {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;

		button {
			background-color: var(--primary);
			border-color: var(--primary);
			color: #fff;
		}

		input[type="number"] {
			border: 2px solid #eaeaea;
			padding: 5px;
			width: 80px;
			height: 40px;
		}

		span {
			margin-right: 10px;
		}

		input {
			width: 50px;
			height: 30px;
			border: 1px solid #ccc;
			border-radius: 5px;
			padding: 0 10px;
		}
	}
}
