header {
	background-color: var(--primary);
	color: var(--light);
	padding: calc( var(--spacing-half) / 3) 0;

	a,
	button {
		color: var(--light) !important;
	}
}
